<ng-content select=".table-header"></ng-content>
<div class="table-container">
    @if (loading()) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
    <table
        #table
        mat-table
        multiTemplateDataRows
        [dataSource]="adapter().normalized()"
        matSort
        [class.sticky-header]="stickyHeader()"
        [matSortDisabled]="loading()"
        [matSortDirection]="adapter().sort?.direction"
        [matSortActive]="adapter().sort?.active"
        (matSortChange)="onSortData($event)"
    >
        <ng-container matColumnDef="select" [sticky]="columns()['select']?.sticky === 'start'" [stickyEnd]="columns()['select']?.sticky === 'end'">
            <th mat-header-cell *matHeaderCellDef>
                @if (maxAllowed() !== 1 && selectedNormalized(); as sns) {
                    <mat-checkbox
                        (change)="toggleSelectAll()"
                        [checked]="sns.length >= adapter().normalized()?.length"
                        [indeterminate]="sns.length && sns.length !== adapter().normalized()?.length"
                    >
                    </mat-checkbox>
                }
            </th>
            <td mat-cell *matCellDef="let row">
                @if (adapter().imageProperty) {
                    <img [src]="row.image" [attr.alt]="row.display || row.key" class="avatar" />
                }
                <mat-checkbox (click)="$event.stopPropagation()" [checked]="selectionModel.isSelected(row.key)" (change)="toggleSelection($event, row)"></mat-checkbox>
            </td>
        </ng-container>

        @for (descriptor of _properties | keyvalue; track descriptor.key; let i = $index) {
            <ng-container matColumnDef="{{ descriptor.key }}" [sticky]="descriptor.value?.sticky === 'start'" [stickyEnd]="descriptor.value?.sticky === 'end'">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    [mat-sort-header]="descriptor.value.sortId || descriptor.key"
                    [arrowPosition]="descriptor.value?.sortArrowPosition || 'after'"
                    [disabled]="descriptor.value?.sortDisabled === true"
                    [class.none]="descriptor.value?.visible === false"
                    [ngStyle]="{ width: descriptor.value?.width + 'px' }"
                    [ngClass]="descriptor.value.class"
                >
                    {{ descriptor.value.header || descriptor.key }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let element; let dataIndex = dataIndex"
                    [class.none]="descriptor.value?.visible === false"
                    [ngStyle]="{ width: descriptor.value?.width + 'px' }"
                    [ngClass]="descriptor.value.class"
                >
                    <div>
                        @for (template of descriptor.value.template ?? []; track $index) {
                            <portal
                                [component]="template['component']"
                                [inputs]="
                                    merge(template.inputs, {
                                        value: element.item | jpointer: descriptor.key,
                                        item: element.item,
                                        element,
                                        descriptor,
                                        dataIndex,
                                    })
                                "
                                [injector]="getRowInjector(element)"
                                [outputs]="template['outputs']"
                            ></portal>
                        } @empty {
                            <cell-template [value]="element.item | jpointer: descriptor.key" [item]="element" [element]="element.item" [descriptor]="descriptor"></cell-template>
                        }
                    </div>
                </td>
            </ng-container>
        }
        <tr mat-header-row *matHeaderRowDef="_columns; sticky: stickyHeader()" [class.selected]="selectedNormalized().length > 0"></tr>

        <tr
            mat-row
            *matRowDef="let row; let i = $index; columns: _columns"
            cdkDrag
            cdkDragDisabled="true"
            [cdkDragData]="row"
            class="{{ rowClass()(row) }}"
            [class.focused]="focusedItem === row"
            [class.selected]="selectionModel.isSelected(row.key)"
            (click)="onClick(row)"
            (touch)="onClick(row)"
            (long-press)="onLongPress(row)"
        ></tr>

        <tr mat-row *matRowDef="let row; columns: _columns; when: isGroup" style="background: #ccc"></tr>

        @if (expandable() !== "none" && expandableTemplate()) {
            <ng-container matColumnDef="expandedDetail" [sticky]="columns()['actions']?.sticky === 'start'">
                <td
                    mat-cell
                    *matCellDef="let element"
                    [attr.colspan]="_columns.length"
                    class="example-element-detail"
                    [@detailExpand]="expanded[element.key]?.() ? 'expanded' : 'collapsed'"
                >
                    <ng-container [ngTemplateOutlet]="expandableTemplate()" [ngTemplateOutletContext]="{ element }"></ng-container>
                </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row" [class.expanded]="expanded[row.key]?.()"></tr>
        }
    </table>
    @if (dataLoaded() && adapter().normalized().length === 0) {
        <div class="no-data" style="text-align: center; padding: 2rem">
            @if (noDataImage()) {
                <img style="width: 100%; max-width: 320px" [src]="noDataImage()" alt="no data" />
            }
            <p>No Data</p>
        </div>
    }

    <!-- @if (!loading()) {
        @if (!items().length) {
            <div id="no-data" style="text-align: center; padding: 2rem">
                @if (noDataImage()) {
                    <img style="width: 100%; max-width: 320px" [src]="noDataImage()" alt="no data" />
                }
                <p>No Data</p>
            </div>
        } @else {}
    } -->
</div>

<!-- Hide paginator when total data is lte page size -->
@if (adapter().page && adapter().page.pageSize) {
    <mat-paginator
        [disabled]="loading() === true"
        [pageIndex]="adapter().page.pageIndex"
        [length]="adapter().page.length"
        [pageSize]="adapter().page.pageSize"
        [pageSizeOptions]="pageSizeOptions()"
        (page)="onPageChange($event)"
        showFirstLastButtons
    ></mat-paginator>
}
