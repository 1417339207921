<mat-form-field #formField [appearance]="appearance()" [floatLabel]="floatLabel()" style="width: 100%">
    @if (label()) {
        <mat-label>{{ label() }}</mat-label>
    }
    <input matInput [type]="type()" [formControl]="control()" [attr.name]="name()" [readonly]="readonly()" [placeholder]="placeholder()" [required]="required()" />
    @if (hint()) {
        <mat-hint>{{ hint() }}</mat-hint>
    }

    <mat-error>
        <span *errors="control().errors; control: control(); let message">{{ message }}</span>
    </mat-error>
</mat-form-field>
