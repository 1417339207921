<mat-form-field
    [appearance]="appearance()"
    [floatLabel]="floatLabel()"
    style="width: 100%"
>
    @if (label()) {
    <mat-label>{{ label() }}</mat-label>
    }
    <mat-date-range-input [rangePicker]="picker">
        <input
            matStartDate
            [(ngModel)]="from"
            [placeholder]="placeholder()"
            (ngModelChange)="propagateChange()"
        />
        <input
            matEndDate
            [(ngModel)]="to"
            [placeholder]="placeholder()"
            (ngModelChange)="propagateChange()"
        />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
