@if (label()) {
    <label>{{ label() }}</label>
}
<input
    #input
    [type]="type()"
    [value]="value()"
    (input)="handleUserInput($event.target?.['value'])"
    (blur)="markAsTouched()"
    [readonly]="readonly()"
    [placeholder]="placeholder()"
/>
<mat-error *errors="control().errors; control: control(); let message">{{ message }}</mat-error>
