<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <li class="mat-tree-node">
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled></button>
            {{node.display}}
        </li>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <li>
            <div class="mat-tree-node">
                <button mat-icon-button matTreeNodeToggle>
                    <mat-icon class="mat-icon-rtl-mirror">{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}</mat-icon>
                </button>
                {{node.display}}
            </div>
            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                <ng-container matTreeNodeOutlet></ng-container>
            </ul>
        </li>
    </mat-nested-tree-node>
</mat-tree>