@if (adapter()) {
    <mat-form-field [appearance]="appearance()" [floatLabel]="floatLabel()" style="width: 100%">
        @if (label()) {
            <mat-label>{{ label() }}</mat-label>
        }
        <input type="text" matInput [matAutocomplete]="auto" #input [required]="required()" [attr.name]="name()" [formControl]="control()" [placeholder]="placeholder()" />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
            @for (item of adapter().normalized(); track item) {
                <mat-option [value]="item.display">
                    @if (item.image) {
                        <img [src]="item.image" />
                    }
                    <span>{{ item.display }}</span>
                </mat-option>
            }
        </mat-autocomplete>
        @if (hint()) {
            <mat-hint>{{ hint() }}</mat-hint>
        }
        <mat-error *errors="control().errors; control: control(); let message">{{ message }}</mat-error>
    </mat-form-field>
}
