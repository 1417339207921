<mat-form-field [appearance]="appearance()" [floatLabel]="floatLabel()" style="width: 100%">
    @if (label()) {
        <mat-label>{{ label() }}</mat-label>
    }
    <input matInput [matDatepicker]="picker" #input [formControl]="control()" [readonly]="readonly()" [placeholder]="placeholder()" [attr.name]="name()" />
    <mat-datepicker-toggle matSuffix touchUi [for]="picker"></mat-datepicker-toggle>
    @if (hint()) {
        <mat-hint>{{ hint() }}</mat-hint>
    }
    <mat-error *errors="control().errors; control: control(); let message">{{ message }}</mat-error>
</mat-form-field>
<mat-datepicker #picker [startView]="startView()" [touchUi]="touchUi()"></mat-datepicker>
