<mat-form-field [appearance]="appearance()" [floatLabel]="floatLabel()" style="width: 100%">
    @if (label()) {
        <mat-label>{{ label() }}</mat-label>
    }
    <textarea
        matInput
        [value]="value()"
        [formControl]="control()"
        [required]="required()"
        [readonly]="readonly()"
        [placeholder]="placeholder()"
        [cdkTextareaAutosize]="true"
        [cdkAutosizeMinRows]="rows()"
        [cdkAutosizeMaxRows]="maxRows()"
        [attr.name]="name()"
    >
    </textarea>
    @if (hint()) {
        <mat-hint>{{ hint() }}</mat-hint>
    }
    <mat-error *errors="control().errors; control: control(); let message">{{ message }}</mat-error>
</mat-form-field>
