@if (label()) {
    <label>{{ label() }}</label>
}
<input
    matInput
    #input
    type="number"
    [value]="value"
    (input)="handleUserInput($event.target?.['value'])"
    (blur)="markAsTouched()"
    [readonly]="readonly()"
    [placeholder]="placeholder()"
/>    <mat-error *errors="control().errors; control: control(); let message">{{ message }}</mat-error>

