@if (label()) {
    <mat-label>{{ label() }}</mat-label>
}

<!-- (selectionChange)="onSelectionChange($event)"
(sortChange)="onSortChange($event)"
(focusedItem)="onFocusedItemChanged($event)" -->
<!-- [tableName]="name()" -->
<data-table
    #dataTable
    [lazyLoadData]="false"
    [adapter]="adapter()"
    [columns]="columns()"
    (itemAdded)="updateValueFromDataSource()"
    (itemRemoved)="updateValueFromDataSource()"
    (itemUpdated)="updateValueFromDataSource()"
    (sortChange)="updateValueFromDataSource()"
>
    @if (tableHeaderComponent(); as template) {
        <portal
            class="table-header"
            [component]="template.component"
            [inputs]="template.inputs"
            [outputs]="template.outputs"
            [content]="template.content"
            [injector]="template.injector"
            [className]="template.class"
        ></portal>
    } @else {
        <ng-content class="table-header"></ng-content>
    }
</data-table>
