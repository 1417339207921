@if (label()) {
    <label>{{ label() }}</label>
}
<textarea
    matInput
    #input
    [value]="value()"
    [formControl]="control()"
    (input)="handleUserInput(input.value)"
    (blur)="markAsTouched()"
    [readonly]="readonly()"
    [placeholder]="placeholder()"
    [rows]="rows()"
    [required]="required()"
></textarea>
<mat-error *errors="control().errors; control: control(); let message">{{ message }}</mat-error>
