@for (f of value(); track $index) {
    <file-template
        class="file"
        [class.selectable]="selectable() === true"
        [file]="f"
        [path]="path()"
        (events)="onFileEvent($event)"
        [selectable]="selectable()"
        [imageDim]="imageDim()"
        [includeAccess]="includeAccess()"
        [base]="base()"
        (click)="focused.set(f)"
    ></file-template>
}
