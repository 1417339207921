<fieldset>
    @if (label()) {
        <legend>{{ label() }}</legend>
    }

    <data-table
        #dataTable
        [lazyLoadData]="false"
        [tableName]="name()"
        [columns]="columns()"
        [adapter]="adapter()"
        (itemAdded)="updateValueFromDataSource()"
        (itemRemoved)="updateValueFromDataSource()"
        (itemUpdated)="updateValueFromDataSource()"
        (sortChange)="updateValueFromDataSource()"
        (sortChange)="updateValueFromDataSource()"
    >
        @if (tableHeaderComponent(); as template) {
            <portal
                class="table-header"
                [component]="template.component"
                [inputs]="template.inputs"
                [outputs]="template.outputs"
                [content]="template.content"
                [injector]="template.injector"
                [className]="template.class"
            ></portal>
        } @else {
            <ng-content class="table-header"></ng-content>
        }
    </data-table>
</fieldset>
