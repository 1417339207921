<input type="hidden" [value]="value()" [formControl]="control()" [required]="required()" />

@if (viewFiles()) {
    <fieldset>
        @if (label()) {
            <legend class="mbe-1">{{ label() }}</legend>
        }
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        <ng-container *ngTemplateOutlet="uploadBtnTemplate"></ng-container>

        @if (control().touched && control().errors) {
            <mat-error *errors="control().errors; control: control(); let message">{{ message }}</mat-error>
        }
    </fieldset>
} @else if (enableDragDrop() === true) {
    @if (label()) {
        <mat-label class="mbe-1">{{ label() }}</mat-label>
    }
    <ng-container *ngTemplateOutlet="dragDropTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="uploadBtnTemplate"></ng-container>
    @if (control().touched && control().errors) {
        <mat-error *errors="control().errors; control: control(); let message">{{ message }}</mat-error>
    }
}
@if (placeholder() && viewModel()?.length === 0) {
    <div class="mt-16" style="display: flex; flex-flow: column">
        {{ placeholder() }}
    </div>
}
<ng-template #uploadBtnTemplate>
    @if (canUpload() && !hideSelectButton()) {
        <div class="upload-button">
            <button mat-raised-button class="mbe-1" type="button" (click)="selectFile()" [color]="color()">
                <!-- @if (uploading()) {
                    <mat-icon class="rotate">loop</mat-icon>
                } @else {
                } -->
                <mat-icon>upload_file</mat-icon>
                Upload
            </button>
            <!-- @for (file of files; track file) {
                <span>{{ file.name }}</span>
            } -->
        </div>
    }
</ng-template>
<ng-template #contentTemplate>
    <file-viewer
        [base]="base()"
        [path]="path()"
        [value]="viewModel()"
        [view]="view()"
        (click)="$event.stopPropagation()"
        [selectable]="false"
        [includeAccess]="includeAccess()"
        (events)="viewerEventsHandler($event)"
        (changed)="selectionChanged($event)"
    ></file-viewer>
</ng-template>

<ng-template #dragDropTemplate>
    <div
        #dr
        class="drop-zone"
        [class.dragging]="dragging()"
        data-role="drag-drop-container"
        tabindex="0"
        (click)="selectFile()"
        (dragleave)="dragLeave($event)"
        (dragover)="$event.preventDefault(); dragOver($event)"
        (drop)="onDrop($event)"
    >
        @if (viewModel().length > 0) {
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        } @else {
            <p class="placeholder">Drop files here</p>
        }
    </div>
</ng-template>
