@let d = descriptor();
@switch (variant()) {
    @case ('raised') {
        <button
            authAction
            [path]="d['path']"
            [action]="d['action']"
            [title]="d.tooltip ?? ''"
            [attr.type]="d.type || 'button'"
            [attr.id]="d.name"
            [disabled]="isDisabled()"
            [color]="color()"
            [matBadge]="d.matBadge"
            [matBadgeColor]="d.matBadgeColor"
            (click)="onAction($event)"
            (keydown.enter)="onAction($event)"
            mat-raised-button
        >
            @if (loading()) {
                <mat-icon><mat-spinner class="spinner" mode="indeterminate" [diameter]="15" [color]="color()"></mat-spinner></mat-icon>
            }
            @if (icon() && !loading()) {
                <mat-icon [attr.disabled]="isDisabled()">{{ icon() }}</mat-icon>
            }
            {{ d.text }}
        </button>
    }
    @case ('flat') {
        <button
            authAction
            [path]="d['path']"
            [action]="d['action']"
            [title]="d.tooltip ?? ''"
            [attr.type]="d.type || 'button'"
            [attr.id]="d.name"
            [disabled]="isDisabled()"
            [color]="color()"
            (keydown.enter)="onAction($event)"
            [matBadge]="d.matBadge"
            [matBadgeColor]="d.matBadgeColor"
            (click)="onAction($event)"
            mat-flat-button
        >
            @if (loading()) {
                <mat-icon><mat-spinner class="spinner" mode="indeterminate" [diameter]="15" [color]="color()"></mat-spinner></mat-icon>
            }
            @if (icon() && !loading()) {
                <mat-icon [attr.disabled]="isDisabled()">{{ icon() }}</mat-icon>
            }
            {{ d.text }}
        </button>
    }
    @case ('stroked') {
        <button
            authAction
            [path]="d['path']"
            [action]="d['action']"
            [title]="d.tooltip ?? ''"
            [attr.type]="d.type || 'button'"
            [attr.id]="d.name"
            [disabled]="isDisabled()"
            [color]="color()"
            (keydown.enter)="onAction($event)"
            [matBadge]="d.matBadge"
            [matBadgeColor]="d.matBadgeColor"
            (click)="onAction($event)"
            mat-stroked-button
        >
            @if (loading()) {
                <mat-icon><mat-spinner class="spinner" mode="indeterminate" [diameter]="15" [color]="color()"></mat-spinner></mat-icon>
            }
            @if (icon() && !loading()) {
                <mat-icon [attr.disabled]="isDisabled()">{{ icon() }}</mat-icon>
            }

            {{ d.text }}
        </button>
    }
    @case ('icon') {
        <button
            authAction
            [path]="d['path']"
            [action]="d['action']"
            [title]="d.tooltip ?? d.text ?? ''"
            [attr.type]="d.type || 'button'"
            [attr.id]="d.name"
            [disabled]="isDisabled()"
            [color]="color()"
            (keydown.enter)="onAction($event)"
            [matBadge]="d.matBadge"
            [matBadgeColor]="d.matBadgeColor"
            (click)="onAction($event)"
            mat-icon-button
            [attr.aria-labelledby]="d.text"
            [attr.aria-label]="d.text"
        >
            @if (loading()) {
                <mat-icon><mat-spinner class="spinner" mode="indeterminate" [diameter]="15" [color]="color()"></mat-spinner></mat-icon>
            }
            @if (icon() && !loading()) {
                <mat-icon [attr.disabled]="isDisabled()">{{ icon() }}</mat-icon>
            }
        </button>
    }
    @case ('fab') {
        <button
            authAction
            [path]="d['path']"
            [action]="d['action']"
            [title]="d.tooltip ?? d.text ?? ''"
            [attr.type]="d.type || 'button'"
            [attr.id]="d.name"
            [disabled]="isDisabled()"
            [color]="color()"
            (keydown.enter)="onAction($event)"
            [matBadge]="d.matBadge"
            [matBadgeColor]="d.matBadgeColor"
            (click)="onAction($event)"
            mat-fab
            [attr.aria-labelledby]="d.text"
            [attr.aria-label]="d.text"
        >
            @if (loading()) {
                <mat-icon><mat-spinner class="spinner" mode="indeterminate" [diameter]="15" [color]="color()"></mat-spinner></mat-icon>
            }
            @if (icon() && !loading()) {
                <mat-icon [attr.disabled]="isDisabled()">{{ icon() }}</mat-icon>
            }
        </button>
    }
    @case ('mini-fab') {
        <button
            authAction
            [path]="d['path']"
            [action]="d['action']"
            [title]="d.tooltip ?? ''"
            [attr.type]="d.type || 'button'"
            [attr.id]="d.name"
            [disabled]="isDisabled()"
            [color]="color()"
            (keydown.enter)="onAction($event)"
            [matBadge]="d.matBadge"
            [matBadgeColor]="d.matBadgeColor"
            (click)="onAction($event)"
            mat-mini-fab
            [attr.aria-labelledby]="d.text"
            [attr.aria-label]="d.text"
        >
            @if (loading()) {
                <mat-icon><mat-spinner class="spinner" mode="indeterminate" [diameter]="15" [color]="color()"></mat-spinner></mat-icon>
            }
            @if (icon() && !loading()) {
                <mat-icon [attr.disabled]="isDisabled()">{{ icon() }}</mat-icon>
            }
        </button>
    }
    @default {
        <button
            authAction
            [path]="d['path']"
            [action]="d['action']"
            [title]="d.tooltip ?? ''"
            [attr.type]="d.type || 'button'"
            [attr.id]="d.name"
            [disabled]="isDisabled()"
            [color]="color()"
            (keydown.enter)="onAction($event)"
            [matBadge]="d.matBadge"
            [matBadgeColor]="d.matBadgeColor"
            (click)="onAction($event)"
            mat-button
        >
            @if (loading()) {
                <mat-icon><mat-spinner class="spinner" mode="indeterminate" [diameter]="15" [color]="color()"></mat-spinner></mat-icon>
            }
            @if (icon() && !loading()) {
                <mat-icon [attr.disabled]="isDisabled()">{{ icon() }}</mat-icon>
            }
            {{ d.text }}
        </button>
    }
}
