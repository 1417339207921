@let selectInput = undefined;
<mat-form-field [appearance]="appearance()" (keydown)="keyDown($event)" [floatLabel]="floatLabel()" style="width: 100%">
    @if (label()) {
        <mat-label>{{ label() }}</mat-label>
    }
    <!-- (click)="$event.stopPropagation(); openedChange(!selectInput.panelOpen, selectInput)" -->
    <!-- (keydown)="keyDown($event, selectInput)" -->
    @if (maxAllowed() !== 1) {
        <mat-select
            #selectInput
            multiple
            (openedChange)="loadData()"
            [formControl]="control()"
            [compareWith]="compareWithFn"
            [panelClass]="panelClass()"
            [placeholder]="placeholder()"
            [required]="required()"
            [attr.name]="name()"
        >
            @if (loading()) {
                <span disabled> Loading ... </span>
            }
            @if (showSearch()) {
                <ng-container [ngTemplateOutlet]="searchBoxTemplate"></ng-container>
            }

            <div class="selected-wrapper">
                <span>{{ selected().length }} Selected</span>
                <span style="flex: 1 1 auto"></span>
                <mat-checkbox [checked]="itemsSource() === 'selected'" (change)="itemsSource.set($event.checked ? 'selected' : 'adapter')" [disabled]="!selected().length"
                    >Show only selected</mat-checkbox
                >
            </div>

            @for (item of items(); track $index) {
                <mat-option [value]="item.value">
                    <ng-container [ngTemplateOutlet]="itemTemplate() ?? defaultItemTemplate" [ngTemplateOutletContext]="{ item: item }"> </ng-container>
                </mat-option>
            } @empty {
                @if (!loading()) {
                    <mat-option disabled> No items found </mat-option>
                }
            }

            @for (item of actions; track $index) {
                <mat-option (click)="onAction($event, item)">
                    @if (item.icon?.length) {
                        <mat-icon>{{ item.icon }}</mat-icon>
                    }
                    <span>{{ item.text }}</span>
                </mat-option>
            }
        </mat-select>
    } @else {
        <!-- (click)="$event.stopPropagation(); openedChange(!selectInput.panelOpen, selectInput)" -->
        <!-- (openedChange)="openedChange($event, selectInput)" -->
        <mat-select
            #selectInput
            (openedChange)="loadData()"
            [formControl]="control()"
            [compareWith]="compareWithFn"
            [placeholder]="placeholder()"
            [panelClass]="panelClass()"
            [required]="required()"
            [attr.name]="name()"
        >
            @if (showSearch()) {
                <ng-container [ngTemplateOutlet]="searchBoxTemplate"></ng-container>
            }
            @if (loading()) {
                <mat-option disabled> Loading ... </mat-option>
            }

            @for (item of items(); track item) {
                <mat-option [value]="item.value">
                    <ng-container [ngTemplateOutlet]="itemTemplate() ?? defaultItemTemplate" [ngTemplateOutletContext]="{ item: item }"> </ng-container>
                </mat-option>
            } @empty {
                @if (dataLoaded()) {
                    <mat-option disabled> No items found </mat-option>
                } @else if(!loading()) {
                    <mat-option disabled> Loading ... </mat-option>
                }
            }

            @for (item of actions; track i; let i = $index) {
                <mat-option (click)="onAction($event, item)">
                    @if (item.icon?.length) {
                        <mat-icon>{{ item.icon }}</mat-icon>
                    }
                    <span>{{ item.text }}</span>
                </mat-option>
            }
        </mat-select>
    }

    <div matSuffix style="display: flex; padding: 0.5rem; gap: 0.5rem">
        @if (loading()) {
            <mat-progress-spinner [diameter]="20" mode="indeterminate"></mat-progress-spinner>
        } @else if (value() && !disabled()) {
            <button matSuffix mat-icon-button (click)="clearValue($event)">
                <mat-icon>clear</mat-icon>
            </button>
        }
    </div>

    @if (hint()) {
        <mat-hint>{{ hint() }}</mat-hint>
    }

    <mat-error *errors="control().errors; control: control(); let message">{{ message }}</mat-error>
</mat-form-field>

<ng-template #searchBoxTemplate>
    <div class="select-filter-wrapper">
        <mat-icon>search</mat-icon>
        <!-- (input)="$event.stopPropagation()" prevent closing the select -->
        <input
            (input)="$event.preventDefault(); $event.stopPropagation()"
            #filterInput
            [focus]="true"
            [placeholder]="placeholder() ?? ''"
            focusType="select"
            style="flex: 1; border: none; outline: none"
            [formControl]="filterControl"
        />
        @if (filterInput.value.length > 0) {
            <button mat-icon-button (click)="$event.stopPropagation(); filterModel.set(''); updateFilter()">
                <mat-icon>clear</mat-icon>
            </button>
        }
    </div>
</ng-template>

<ng-template #defaultItemTemplate let-item="item">
    @if (item.image) {
        <img #img style="max-height: 3em; margin-bottom: -16px; margin-inline-end: 10px" [src]="item.image" (error)="img.onerror = null; img.src = ''" />
    }
    <span>{{ item.display }}</span>
</ng-template>
