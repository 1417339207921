@if (loading()) {
<mat-progress-bar color="accent" mode="query"></mat-progress-bar>
}
<div class="grid">
    @for (t of adapter().normalized(); track t) {
    <div class="upload-task-box" [class.selected]="chkBox.checked">
        <mat-checkbox
            #chkBox
            [checked]="selectionModel.isSelected(t.value)"
            (change)="toggle(t.value)"
        ></mat-checkbox>
        @if (t.item.mimetype.startsWith('image/')) {
        <image
            [src]="t.item.path"
            [width]="100"
            [height]="100"
            [includeAccess]="true"
            [alt]="t.key"
        ></image>
        } @else { @if (t.item.mimetype.startsWith('application/octet-stream')) {
        <svg
            height="100"
            width="100"
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="bin-file">
                <path d="m21 7h40v54h-40z" fill="#ff7956" />
                <path
                    d="m49 7 8 8h-10a2.006 2.006 0 0 1 -2-2v-10z"
                    fill="#ffda44"
                />
                <path
                    d="m57 15v42h-40v-54h28v10a2.006 2.006 0 0 0 2 2z"
                    fill="#ffeb99"
                />
                <path d="m3 22h50v20h-50z" fill="#2488ff" />
                <path d="m17 42h37v1h-37z" fill="#bfb073" />
                <path d="m53 23h1v20h-1z" fill="#bfb073" />
                <path d="m21 57h37v1h-37z" fill="#bf5b41" />
                <path d="m58 58h-1v-42l1 1z" fill="#bf5b41" />
                <path d="m57 15v1h-8a2 2 0 0 1 -1.73-1z" fill="#bfb073" />
                <g fill="#fff">
                    <path
                        d="m18 25h-6a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h6a3 3 0 0 0 3-3v-2a2.981 2.981 0 0 0 -.78-2 2.981 2.981 0 0 0 .78-2v-2a3 3 0 0 0 -3-3zm1 3v2a1 1 0 0 1 -1 1h-5v-4h5a1 1 0 0 1 1 1zm0 8a1 1 0 0 1 -1 1h-5v-4h5a1 1 0 0 1 1 1z"
                    />
                    <path d="m23 27h4v10h-4v2h10v-2h-4v-10h4v-2h-10z" />
                    <path
                        d="m43 34.7-6.168-9.252a1 1 0 0 0 -1.832.552v13h2v-9.7l6.168 9.252a1 1 0 0 0 1.832-.552v-13h-2z"
                    />
                </g>
            </g>
        </svg>
        } }
        <footer class="footer">
            <span class="file-name">{{ t.display }}</span>
            <span class="spacer"></span>
            <button class="action" (click)="remove(t)" mat-icon-button>
                <mat-icon>delete_outline</mat-icon>
            </button>
        </footer>
    </div>
    }
</div>

<div style="align-self: flex-end">
    <button mat-button color="primary" (click)="apply()">select</button>
</div>
