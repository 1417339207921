@if (!hasError()) {
    <img #renderer [attr.loading]="loading()" [src]="source" [alt]="alt" (error)="errorHandler($event, renderer)" [width]="width()" [height]="height()" [ngStyle]="styles()" />
} @else {
    <ng-template [ngTemplateOutlet]="errorTemplate"></ng-template>
}
<ng-template #defaultErrorTemplate>
    <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 256 256"
        version="1.1"
        [ngStyle]="styles()"
    >
        <g>
            <path
                id="path870"
                d="M 32,48 V 207.9236"
                style="
                    fill: none;
                    stroke: #000000;
                    stroke-width: 15.9923439;
                    stroke-linecap: round;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 4;
                    stroke-dasharray: none;
                    stroke-opacity: 1;
                "
            />
            <path
                id="path874"
                d="M 224,96 V 208"
                style="
                    fill: none;
                    stroke: #000000;
                    stroke-width: 15.9923439;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 4;
                    stroke-dasharray: none;
                    stroke-opacity: 1;
                "
            />
            <path
                id="path880"
                d="m 64,16 h 80"
                style="
                    fill: none;
                    stroke: #000000;
                    stroke-width: 15.9923439;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 4;
                    stroke-dasharray: none;
                    stroke-opacity: 1;
                "
            />
            <path
                id="path882"
                d="M 64,240 H 192"
                style="
                    fill: none;
                    stroke: #000000;
                    stroke-width: 15.9923439;
                    stroke-linecap: round;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 4;
                    stroke-dasharray: none;
                    stroke-opacity: 1;
                "
            />
            <path
                style="
                    fill: none;
                    stroke: #000000;
                    stroke-width: 15.9923439;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 4;
                    stroke-dasharray: none;
                    stroke-opacity: 1;
                "
                d="m 224,208 c 0.0874,15.98169 -16,32 -32,32"
                id="path886"
            />
            <path
                transform="scale(-1,1)"
                style="
                    fill: none;
                    stroke: #000000;
                    stroke-width: 15.9923439;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 4;
                    stroke-dasharray: none;
                    stroke-opacity: 1;
                "
                d="m -32,208 c -10e-7,16 -16,32 -32,32"
                id="path886-3"
            />
            <path
                transform="scale(-1)"
                style="
                    fill: none;
                    stroke: #000000;
                    stroke-width: 15.9923439;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 4;
                    stroke-dasharray: none;
                    stroke-opacity: 1;
                "
                d="M -32,-47.976784 C -32,-32 -48,-16.356322 -63.999997,-16.000002"
                id="path886-3-6"
            />
            <path
                id="path933"
                d="M 223.91257,96.071779 144,16"
                style="
                    fill: none;
                    stroke: #000000;
                    stroke-width: 15.9923439;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 4;
                    stroke-dasharray: none;
                    stroke-opacity: 1;
                "
            />
            <path
                transform="scale(-1,1)"
                style="
                    fill: none;
                    stroke: #000000;
                    stroke-width: 15.9923439;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 4;
                    stroke-dasharray: none;
                    stroke-opacity: 1;
                "
                d="m -144,64 c -0.0492,15.912926 -16.06452,31.999995 -32,32"
                id="path886-5"
            />
            <path
                id="path950"
                d="M 144,64 V 16"
                style="
                    fill: none;
                    stroke: #000000;
                    stroke-width: 15.9923439;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 4;
                    stroke-dasharray: none;
                    stroke-opacity: 1;
                "
            />
            <path
                id="path952"
                d="m 176,96 h 48"
                style="
                    fill: none;
                    stroke: #000000;
                    stroke-width: 15.9923439;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 4;
                    stroke-dasharray: none;
                    stroke-opacity: 1;
                "
            />
            <path
                style="
                    fill: none;
                    stroke: #000000;
                    stroke-width: 16;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-opacity: 1;
                    stroke-miterlimit: 4;
                    stroke-dasharray: none;
                "
                d="m 64,208 48,-64"
                id="path827"
            />
            <path
                style="
                    fill: none;
                    stroke: #000000;
                    stroke-width: 16;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-opacity: 1;
                    stroke-miterlimit: 4;
                    stroke-dasharray: none;
                "
                d="m 64,144 48,64"
                id="path829"
            />
        </g>
    </svg>
</ng-template>
