<form
    #addressForm="ngForm"
    (ngSubmit)="onSubmit($event)"
    [formGroup]="addressFormGroup"
>
    <input type="hidden" [value]="value()" />
    @if (label()) {
    <fieldset>
        <legend>{{ label() }}</legend>
        <ng-container *ngTemplateOutlet="noLabelTemplate"></ng-container>
    </fieldset>
    } @else {
    <div style="display: flex; flex-flow: column nowrap">
        <div style="display: flex">
            <form-select
                class="address-field"
                id="address-country"
                [required]="required()"
                [adapter]="countryAdapter"
                placeholder="Country"
                label="country"
                formArrayName="country"
            ></form-select>
            @if (getControl('state')) {
            <span style="width: 2rem"></span>
            <mat-form-field
                class="address-field"
                id="address-state"
                [appearance]="appearance()"
            >
                <mat-label>State</mat-label>
                <input
                    matInput
                    formControlName="state"
                    [disabled]="disabled()"
                    [required]="required()"
                    placeholder="State"
                />
                @for (error of getControl('state').errors | keyvalue; track
                error) {
                <mat-error>
                    {{control().errors?.[error.key+''] || error.key}}</mat-error
                >
                }
            </mat-form-field>
            }
        </div>
        <div style="display: flex">
            @if (getControl('city')) {
            <mat-form-field
                class="address-field"
                id="address-city"
                [appearance]="appearance()"
            >
                <mat-label>City</mat-label>
                <input
                    matInput
                    formControlName="city"
                    [disabled]="disabled()"
                    [required]="required()"
                    placeholder="City"
                />
                @for (error of getControl('city').errors | keyvalue; track
                error) {
                <mat-error>
                    {{control().errors?.[error.key+''] || error.key}}</mat-error
                >
                }
            </mat-form-field>
            } @if (getControl('zipCode')) {
            <span style="width: 2rem"></span>
            <mat-form-field
                class="address-field"
                id="address-zipCode"
                [appearance]="appearance()"
            >
                <mat-label>ZipCode</mat-label>
                <input
                    matInput
                    formControlName="zipCode"
                    [disabled]="disabled()"
                    placeholder="Zip Code"
                />
                @for (error of getControl('zipCode').errors | keyvalue; track
                error) {
                <mat-error>
                    {{control().errors?.[error.key+''] || error.key}}</mat-error
                >
                }
            </mat-form-field>
            }
        </div>
        @if (getControl('addressLine1')) {
        <mat-form-field
            class="address-field"
            id="address-line-1"
            [appearance]="appearance()"
        >
            <mat-label>address-line-1</mat-label>
            <input
                matInput
                formControlName="addressLine1"
                [disabled]="disabled()"
                [required]="required()"
                placeholder="Building Number, Road Number"
            />
            @for (error of getControl('addressLine1').errors | keyvalue; track
            error) {
            <mat-error>
                {{control().errors?.[error.key+''] || error.key}}</mat-error
            >
            }
        </mat-form-field>
        } @if (getControl('addressLine2')) {
        <mat-form-field
            class="address-field"
            id="address-line-2"
            [appearance]="appearance()"
        >
            <mat-label>Address Line</mat-label>
            <input
                matInput
                formControlName="addressLine2"
                [disabled]="disabled()"
                [required]="required()"
                placeholder="House number, Suite number, Floor numbers, Room number, PO Box number "
            />
            @for (error of getControl('addressLine2').errors | keyvalue; track
            error) {
            <mat-error>
                {{control().errors?.[error.key+''] || error.key }}</mat-error
            >
            }
        </mat-form-field>
        }
    </div>
    }
    <ng-template #noLabelTemplate>
        <div style="display: flex; flex-flow: column nowrap">
            <div style="display: flex">
                <form-select
                    class="address-field"
                    id="address-country"
                    [required]="required()"
                    [adapter]="countryAdapter"
                    placeholder="Country"
                    label="Country"
                    formArrayName="country"
                ></form-select>
                @if (getControl('state')) {
                <span style="width: 2rem"></span>
                <mat-form-field
                    class="address-field"
                    id="address-state"
                    [appearance]="appearance()"
                >
                    <mat-label>State</mat-label>
                    <input
                        matInput
                        formControlName="state"
                        [disabled]="disabled()"
                        [required]="required()"
                        placeholder="State"
                    />
                    @for (error of getControl('state').errors | keyvalue; track
                    error) {
                    <mat-error>
                        {{control().errors?.[error.key+''] || error.key }}</mat-error
                    >
                    }
                </mat-form-field>
                }
            </div>

            <div style="display: flex">
                @if (getControl('city')) {
                <mat-form-field
                    class="address-field"
                    id="address-city"
                    [appearance]="appearance()"
                >
                    <mat-label>City</mat-label>
                    <input
                        matInput
                        formControlName="city"
                        [disabled]="disabled()"
                        [required]="required()"
                        placeholder="City"
                    />
                    @for (error of getControl('city').errors | keyvalue; track
                    error) {
                    <mat-error>
                        {{control().errors?.[error.key+''] || error.key }}</mat-error
                    >
                    }
                </mat-form-field>
                } @if (getControl('zipCode')) {
                <span style="width: 2rem"></span>
                <mat-form-field
                    class="address-field"
                    id="address-zipCode"
                    [appearance]="appearance()"
                >
                    <mat-label>Zip Code</mat-label>
                    <input
                        matInput
                        formControlName="zipCode"
                        [disabled]="disabled()"
                        placeholder="Zip Code"
                    />
                    @for (error of getControl('zipCode').errors | keyvalue;
                    track error) {
                    <mat-error>
                        {{control().errors?.[error.key+''] || error.key }}</mat-error
                    >
                    }
                </mat-form-field>
                }
            </div>
            @if (getControl('addressLine1')) {
            <mat-form-field
                class="address-field"
                id="address-line-1"
                [appearance]="appearance()"
            >
                <mat-label>Address Line</mat-label>
                <input
                    matInput
                    formControlName="addressLine1"
                    [disabled]="disabled()"
                    [required]="required()"
                    placeholder="Building Number, Road Number "
                />
                @for (error of getControl('addressLine1').errors | keyvalue;
                track error) {
                <mat-error>
                    {{control().errors?.[error.key+''] || error.key }}</mat-error
                >
                }
            </mat-form-field>
            } @if (getControl('addressLine2')) {
            <mat-form-field
                class="address-field"
                id="address-line-2"
                [appearance]="appearance()"
            >
                <mat-label>Address Line</mat-label>
                <input
                    matInput
                    formControlName="addressLine2"
                    [disabled]="disabled()"
                    [required]="required()"
                    placeholder="House number, Suite number, Floor numbers, Room number, PO Box number "
                />
                @for (error of getControl('addressLine2').errors | keyvalue;
                track error) {
                <mat-error>
                    {{control().errors?.[error.key+''] || error.key }}</mat-error
                >
                }
            </mat-form-field>
            }
        </div>
    </ng-template>
</form>
