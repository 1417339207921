<mat-form-field [appearance]="appearance()" [floatLabel]="floatLabel()" style="width: 100%">
    @if (label()) {
        <mat-label>{{ label() }}</mat-label>
    }
    <input
        matInput
        #input
        [type]="showPassword() === false ? 'password' : 'text'"
        [autocomplete]="autocomplete()"
        [value]="value()"
        (input)="handleUserInput($event.target?.['value'])"
        (blur)="markAsTouched()"
        [readonly]="readonly()"
        [placeholder]="placeholder()"
        [attr.name]="name()"
        [formControl]="control()"
    />
    <button tabindex="-1" type="button" matSuffix mat-icon-button (click)="$event.stopPropagation(); showPassword.set(!showPassword())">
        <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>

    @if (canGenerateRandomPassword() === true) {
        <button tabindex="-1" type="button" matSuffix mat-icon-button (click)="$event.stopPropagation(); generateRandomPassword()">
            <mat-icon>key</mat-icon>
        </button>
    }
    @if (showConfirmPasswordInput() !== true) {
        <ng-container *ngTemplateOutlet="errorsTemplate"></ng-container>
    }
</mat-form-field>

@if (showConfirmPasswordInput() && confirmControl) {
    <mat-form-field [appearance]="appearance()" [floatLabel]="floatLabel()" style="width: 100%">
        <input
            matInput
            #input
            [type]="showPassword() !== false ? 'text' : 'password'"
            (input)="confirmControl.markAsDirty()"
            [autocomplete]="autocomplete()"
            (blur)="changeTouchedStatus(confirmControl)"
            [readonly]="readonly()"
            placeholder="Confirm Password"
            [formControl]="confirmControl"
            [attr.name]="name() + '-confirm'"
        />
        @if (showConfirmPasswordInput() === true) {
            <ng-container *ngTemplateOutlet="errorsTemplate"></ng-container>
        }
    </mat-form-field>
}

<ng-template #errorsTemplate>
    @if (hint()) {
        <mat-hint>{{ hint() }}</mat-hint>
    }
    <mat-error *errors="control().errors; control: control(); let message">{{ message }}</mat-error>
</ng-template>
