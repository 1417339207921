mat-dialog-container {
    margin: 0;
    padding: 0;
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
}

.dialog-wrapper-container {
    display: grid;
    // grid-template-rows: auto 1fr auto;
    grid-template-rows: minmax(0, auto) 1fr minmax(0, auto);
    overflow: hidden;
    &.scroll-y {
        .body {
            overflow-y: auto;
        }
    }
    height: 100%;
    // width: calc(100% + 48px);
    // margin: -24px !important;
    width: 100%;
    margin: 0 !important;

    .pad-all-1 {
        padding: 1rem;
    }

    .body {
        margin: 0;
        padding: 0;
        flex: 1 1 auto;
        overflow: hidden;
    }

    & .toolbar {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem !important;
        margin: 0 !important;
        &.header.titled {
            border-radius: 3px 3px 0 0;

            h2 {
                font-size: 1.1rem;
                color: var(--mdc-dialog-subhead-color);
            }
            .close-btn {
                color: var(--mdc-dialog-subhead-color);
            }
        }

        &.footer {
            justify-content: flex-end;
            border-radius: 0 0 3px 3px;
            padding: 0.5rem 1rem !important;
            & > .action:not(:last-child) {
                margin-inline-end: 0.5rem;
            }
        }
    }
}
