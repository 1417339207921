<div style="margin: 0; display: flex; align-items: flex-start">
    @if (template() !== 'toggle') {
        <mat-slide-toggle
            style="max-width: 40px; margin-inline-end: 0.5rem"
            (change)="handleUserInput($event.source?.checked)"
            [formControl]="control()"
            [checked]="value"
            (blur)="markAsTouched()"
        >
        </mat-slide-toggle>
    } @else {
        @if (template()) {
            <mat-checkbox
                style="max-width: 40px; margin-inline-end: 0.5rem"
                (change)="handleUserInput($event.checked)"
                [formControl]="control()"
                [checked]="value"
                (blur)="markAsTouched()"
            >
            </mat-checkbox>
        }
    }
    @if (label()) {
        <label [attr.for]="name()">
            <paragraph [text]="label()" [renderer]="renderer()"></paragraph>
        </label>
    }
</div>
@if (hint()) {
    <mat-hint>{{ hint() }}</mat-hint>
}
<mat-error *errors="control().errors; control: control(); let message">{{ message }}</mat-error>
